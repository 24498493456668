import actions from './actions';
import { AuthState } from './types.d';

const initState: AuthState = {
  cookieName: 'id_token',
  idToken: null,
  companyToken: null,
  loginResponse: null,
  profile: {
    nome: '',
    emp: null,
    sub: null,
    razao_social: '',
    email: '',
    tipo: 0,
  },
  companies: null,
  loading: false,
};

export default function authReducer(state = initState, action: any) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return { ...state, loginResponse: action.payload };

    case actions.SWITCH_USER_COMPANY_REQUEST:
      return { ...state, loading: true };

    case actions.SWITCH_USER_COMPANY_SUCCESS:
      return { ...state, idToken: action.payload, loading: false };

    case actions.SET_COOKIE_NAME:
      return { ...state, cookieName: action.payload };

    case actions.FETCH_TOKEN_SUCCESS:
      return {
        ...state,
        idToken: action.payload.idToken,
        profile: action.payload.profile,
      };

    case actions.GET_USER_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
      };

    case actions.LOGOUT_SUCCESS:
      return initState;

    default:
      return state;
  }
}
