import { SnackbarPayload, DialogPayload } from './types.d';

export function getView(width: number) {
  let newView = 'MobileView';
  if (width >= 1280) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}

const actions = {
  TOGGLE_ALL: 'TOGGLE_ALL',
  IS_LOADING: 'IS_LOADING',
  TOGGLE_SNACKBAR: 'TOGGLE_SNACKBAR',
  TOGGLE_DIALOG: 'TOGGLE_DIALOG',
  HANDLE_ERRORS: 'HANDLE_ERRORS',
  HANDLE_SUCCESS: 'HANDLE_SUCCESS',
  TOGGLE_DRAWER: 'TOGGLE_DRAWER',

  toggleDialog: (dialog: DialogPayload) => ({
    type: actions.TOGGLE_DIALOG,
    payload: dialog,
  }),

  toggleDrawer: (drawer: boolean) => ({
    type: actions.TOGGLE_DRAWER,
    payload: drawer,
  }),

  toggleSnackBar: (notification: SnackbarPayload) => {
    return {
      type: actions.TOGGLE_SNACKBAR,
      payload: notification,
    };
  },

  toggleAll: (width: number, height: number) => {
    const view = getView(width);
    const collapsed = false;
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
    };
  },

  setLoading: (loading: boolean) => ({
    type: actions.IS_LOADING,
    payload: loading,
  }),

  handleErrors: (err: any) => ({
    type: actions.HANDLE_ERRORS,
    payload: err,
  }),
};
export default actions;
