import actions from './actions';
import { InitStateProps } from './types.d';

const initState: InitStateProps = {
  companyInfo: {
    nome: '',
    emp_codigo: null,
  },
};

export default function companyReducer(state = initState, action: any) {
  switch (action.type) {
    case actions.FETCH_COMPANYINFO_SUCCESS: {
      return { ...state, companyInfo: action.payload.data };
    }

    default:
      return state;
  }
}
