import { createStore, applyMiddleware, Store } from 'redux';
import withRedux from 'next-redux-wrapper';
import nextReduxSaga from 'next-redux-saga';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from './rootSaga';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as Sentry from '@sentry/react';
import { State } from './types';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export function configureStore(initialState: State): Store {
  const store = createStore(
    reducers, // @ts-ignore
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares), sentryReduxEnhancer)
  );

  // @ts-ignore
  store.runSagaTask = () => {
    // @ts-ignore
    store.sagaTask = sagaMiddleware.run(rootSaga);
  };

  // @ts-ignore
  store.runSagaTask();
  return store;
}

export function withReduxSaga(BaseComponent: any) {
  return withRedux(configureStore)(nextReduxSaga(BaseComponent));
}
