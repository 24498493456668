import actions, { getView } from './actions';
import { InitStateProps } from './types.d';

const initState: InitStateProps = {
  view: getView(0),
  height: 0,
  loading: null,
  drawer: true,
  snackbar: {
    activated: false,
    time: 3000,
    title: '',
    message: '',
    variant: 'success',
  },
  dialog: {
    activated: false,
    title: '',
    content: '',
    actions: null,
    onSubmit: null,
    initialValues: {},
    validate: null,
    dialogContentProps: {},
    dialogProps: {},
  },
};
export default function appReducer(state = initState, action: any) {
  const { view } = state;
  switch (action.type) {
    case actions.TOGGLE_DIALOG:
      return { ...state, dialog: action.payload };

    case actions.TOGGLE_SNACKBAR:
      if (!action.payload.time) {
        action.payload.time = 5000;
      }
      return { ...state, snackbar: action.payload };

    case actions.TOGGLE_ALL:
      if (view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          view: action.view,
          height: height,
        };
      }
      break;

    case actions.IS_LOADING:
      return { ...state, loading: action.payload };

    case actions.TOGGLE_DRAWER:
      return { ...state, drawer: action.payload };

    default:
      return state;
  }
  return state;
}
