import React from 'react';
import { all, takeEvery, put, spawn, select } from 'redux-saga/effects';
import appActions from './actions';
import Router from 'next/router';
import { removeCookie } from '../../helpers/session';

import { actionHandleErrors, actionHandleSuccess } from './types';

export const getToken = (state: any) => state.Auth.token;
export const getCookieName = (state: any) => state.Auth.cookieName;

export function* handleErrors() {
  yield takeEvery(appActions.HANDLE_ERRORS, function* ({ payload }: actionHandleErrors) {
    if (payload) {
      if (payload.error) {
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: appActions.TOGGLE_SNACKBAR,
          payload: {
            activated: true,
            message: payload.error,
            title: 'Aviso',
            variant: 'danger',
            time: 3000,
          },
        });
      } else if (payload.errors) {
        yield put({ type: appActions.IS_LOADING, payload: null });
        const { errors } = payload;
        if (Array.isArray(errors)) {
          const count = errors.length;
          if (!errors.type) {
            if (count === 1) {
              yield put({
                type: appActions.TOGGLE_SNACKBAR,
                payload: {
                  activated: true,
                  message: errors[0],
                  variant: 'danger',
                  time: payload.time ? payload.time : 3000,
                },
              });
            } else if (count > 1) {
              yield put({
                type: appActions.TOGGLE_DIALOG,
                payload: {
                  activated: true,
                  title: 'Erros',
                  content: (
                    <ul>
                      {errors.map((obj, index) => {
                        return <li key={index}>{obj}</li>;
                      })}
                    </ul>
                  ),
                  actions: true,
                },
              });
            }
          }
        }
      } else if (payload.authErrors) {
        yield put({ type: appActions.IS_LOADING, payload: null });
        const cookieName: string = yield select(getCookieName);
        yield removeCookie(cookieName);
        if (window.location.href?.includes('app.')) {
          return (window.location.href = window.location.href?.split('app.')[1]);
        } else {
          return Router.push('/signin');
        }
      }
    }
  });
}

export function* handleSuccess() {
  yield takeEvery(appActions.HANDLE_SUCCESS, function* ({ payload }: actionHandleSuccess) {
    if (payload && payload.messages) {
      yield put({ type: appActions.IS_LOADING, payload: null });
      if (Array.isArray(payload.messages)) {
        const count = payload.messages.length;
        if (count === 1) {
          yield put({
            type: appActions.TOGGLE_SNACKBAR,
            payload: {
              activated: true,
              message: payload.messages[0],
              variant: 'success',
            },
          });
        } else if (count >= 1) {
          yield put({
            type: appActions.TOGGLE_DIALOG,
            payload: {
              activated: true,
              title: 'Sucesso!',
              actions: true,
              content: (
                <ul>
                  {payload.messages.map((obj, index) => (
                    <li key={index}>{obj}</li>
                  ))}
                </ul>
              ),
            },
          });
        }
      }
    }
  });
}

export default function* rootSaga() {
  yield all([spawn(handleErrors), spawn(handleSuccess)]);
}
