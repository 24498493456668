import { all, takeEvery, put, call, fork, spawn, select } from 'redux-saga/effects';
import Api from '../../helpers/api';
import actions from './actions';
import appActions from '../app/actions';

export const getToken = (state: any) => state.Auth.idToken;
export const getCookieName = (state: any) => state.Auth.cookieName;

export default function* fetchCompanyInfo() {
  yield takeEvery(actions.FETCH_COMPANYINFO_REQUEST, function* () {
    try {
      yield put({ type: appActions.IS_LOADING, payload: true });
      const result: { data: any } = yield call(Api.getCompanyInfo);

      yield put({
        type: actions.FETCH_COMPANYINFO_SUCCESS,
        payload: result,
      });
      yield put({ type: appActions.IS_LOADING, payload: false });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}
export function* rootSaga() {
  yield all([spawn(fetchCompanyInfo)]);
}
