import { all, takeEvery, put, call, fork, spawn, select } from 'redux-saga/effects';
import Api from '../../helpers/api';
import actions from './actions';
import appActions from '../app/actions';
import authActions from '../auth/actions';
import {
  actionFetchFinancialBudgetRequest,
  actionFetchStudentRequest,
  actionInsertSaleRequest,
  actionUpdateStudentRequest,
} from './types';
import api from '../../helpers/api';
import redirect from '../../helpers/redirect';
import { Meta } from '../types';

export const getToken = (state: any) => state.Auth.idToken;
export const getCookieName = (state: any) => state.Auth.cookieName;
export const getFinancialList = (state: any) => state.Student.listFinancial;
export const getFilesList = (state: any) => state.Student.listFiles;
export const getStudentId = (state: any) => state.Student.student.codigo;

export function* fetchStudentRequest() {
  yield takeEvery(actions.FETCH_STUDENT_REQUEST, function* ({}: actionFetchStudentRequest) {
    try {
      yield put({ type: appActions.IS_LOADING, payload: true });
      let token: string = yield select(getToken);
      const result: any = yield call(Api.fetchStudent, token);
      if (result.errors || result.error || result.authErrors) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: result });
      }
      yield put({
        type: actions.FETCH_STUDENT_SUCCESS,
        payload: result,
      });
      yield put({ type: appActions.IS_LOADING, payload: false });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}

export function* insertSaleRequest() {
  yield takeEvery(actions.INSERT_SALE_REQUEST, function* ({ payload }: actionInsertSaleRequest) {
    try {
      yield put({ type: appActions.IS_LOADING, payload: true });
      let token: string = yield select(getToken);
      const result: any = yield call(Api.insertSale, payload, token);
      if (result.errors || result.error || result.authErrors) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: result });
      }
      yield put({
        type: actions.INSERT_SALE_SUCCESS,
        payload: result,
      });

      yield put({ type: appActions.IS_LOADING, payload: false });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}
export function* updateStudentRequest() {
  yield takeEvery(
    actions.UPDATE_STUDENT_REQUEST,
    function* ({ payload }: actionUpdateStudentRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        let token: string = yield select(getToken);
        const result: any = yield call(Api.updateStudent, payload, token);
        if (result.errors || result.error || result.authErrors) {
          yield put({ type: appActions.HANDLE_ERRORS, payload: result });
        }
        yield put({
          type: actions.UPDATE_STUDENT_SUCCESS,
          payload: result,
        });
        redirect({}, '/dashboard?page=3');

        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchFinancialListRequest() {
  yield takeEvery(actions.FETCH_FINANCIAL_LIST_REQUEST, function* ({}) {
    try {
      let list: { data: any; meta: Meta } = yield select(getFinancialList);
      let token: string = yield select(getToken);
      yield put({ type: appActions.IS_LOADING, payload: true });
      const result: { data: any; meta: Meta } = yield call(api.getFinancialList, token);
      if ((result.meta && result.meta.current_page === 1) || !list.data) {
        yield put({
          type: actions.FETCH_FINANCIAL_LIST_SUCCESS,
          payload: result,
        });
      } else {
        yield put({
          type: actions.FETCH_FINANCIAL_LIST_SUCCESS,
          payload: {
            ...list,
            data: [...list.data, ...result.data],
            meta: result.meta,
          },
        });
      }

      yield put({ type: appActions.IS_LOADING, payload: false });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}

export function* fetchFilesListRequest() {
  yield takeEvery(actions.FETCH_FILES_LIST_REQUEST, function* ({}) {
    try {
      let list: { data: any; meta: Meta } = yield select(getFilesList);
      let token: string = yield select(getToken);
      const result: { data: any; meta: Meta } = yield call(api.getFilesList, token);
      if ((result.meta && result.meta.current_page === 1) || !list.data) {
        yield put({
          type: actions.FETCH_FILES_LIST_SUCCESS,
          payload: result,
        });
      } else {
        yield put({
          type: actions.FETCH_FILES_LIST_SUCCESS,
          payload: {
            ...list,
            data: [...list.data, ...result.data],
            meta: result.meta,
          },
        });
      }
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}

export function* insertSaleSuccess() {
  yield takeEvery(actions.INSERT_SALE_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'Venda realiza com sucesso!', title: 'Sucesso' },
    });
    if (payload.token) {
      yield put({
        type: authActions.FETCH_TOKEN_REQUEST,
        payload: payload.token,
      });
      yield put({
        type: actions.FETCH_STUDENT_REQUEST,
      });
    } else {
      yield put({
        type: actions.FETCH_FINANCIAL_LIST_REQUEST,
      });
    }
    redirect({}, '/dashboard');
  });
}

export default function* rootSaga() {
  yield all([
    spawn(fetchStudentRequest),
    spawn(insertSaleRequest),
    spawn(updateStudentRequest),
    spawn(fetchFinancialListRequest),
    spawn(fetchFilesListRequest),
    fork(insertSaleSuccess),
  ]);
}
