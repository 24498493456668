import { LoginPayload, SignupPayload } from './types.d';

const actions = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  FETCH_TOKEN_REQUEST: 'FETCH_TOKEN_REQUEST',
  FETCH_TOKEN_SUCCESS: 'FETCH_TOKEN_SUCCESS',
  SET_COOKIE_NAME: 'SET_COOKIE_NAME',
  VERIFY_TOKEN_INVITE_REQUEST: 'VERIFY_TOKEN_INVITE_REQUEST',
  VERIFY_TOKEN_INVITE_SUCCESS: 'VERIFY_TOKEN_INVITE_SUCCESS',
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  VERIFY_RESET_PASSWORD_TOKEN_REQUEST: 'VERIFY_RESET_PASSWORD_TOKEN_REQUEST',
  VERIFY_RESET_PASSWORD_TOKEN_SUCCESS: 'VERIFY_RESET_PASSWORD_TOKEN_SUCCESS',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  REDIRECT_WITH_TOKEN_REQUEST: 'REDIRECT_WITH_TOKEN_REQUEST',
  REDIRECT_WITH_TOKEN_SUCCESS: 'REDIRECT_WITH_TOKEN_SUCCESS',
  GET_USER_COMPANIES_REQUEST: 'GET_USER_COMPANIES_REQUEST',
  GET_USER_COMPANIES_SUCCESS: 'GET_USER_COMPANIES_SUCCESS',
  SWITCH_USER_COMPANY_REQUEST: 'SWITCH_USER_COMPANY_REQUEST',
  SWITCH_USER_COMPANY_SUCCESS: 'SWITCH_USER_COMPANY_SUCCESS',

  login: (payload: LoginPayload) => ({
    type: actions.LOGIN_REQUEST,
    payload,
  }),
  logout: () => ({
    type: actions.LOGOUT_REQUEST,
  }),
  fetchTokenData: (payload: string) => ({
    type: actions.FETCH_TOKEN_REQUEST,
    payload,
  }),
  setCookieName: (payload: string) => ({
    type: actions.SET_COOKIE_NAME,
    payload,
  }),
  verifyTokenInvite: (token: string) => ({
    type: actions.VERIFY_TOKEN_INVITE_REQUEST,
    payload: token,
  }),
  redirectWithToken: (payload: string) => ({
    type: actions.REDIRECT_WITH_TOKEN_REQUEST,
    payload,
  }),
  signup: (payload: SignupPayload) => ({
    type: actions.SIGNUP_REQUEST,
    payload,
  }),
  getUserCompanies: () => ({
    type: actions.GET_USER_COMPANIES_REQUEST,
  }),
  switchUserCompany: (payload: string) => ({
    type: actions.SWITCH_USER_COMPANY_REQUEST,
    payload,
  }),
  forgotPassword: (payload: { email: string }, callback?: () => void) => ({
    type: actions.FORGOT_PASSWORD_REQUEST,
    payload,
    callback,
  }),
  resetPassword: (
    payload: {
      password: string;
      confirm_password: string;
      token: string;
    },
    callback: () => void
  ) => ({
    type: actions.RESET_PASSWORD_REQUEST,
    payload,
    callback,
  }),
};
export default actions;
