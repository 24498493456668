import { CompanyInfoRequest } from './types.d';

const actions = {
  FETCH_COMPANYINFO_SUCCESS: 'FETCH_COMPANYINFO_SUCCESS',
  FETCH_COMPANYINFO_REQUEST: 'FETCH_COMPANYINFO_REQUEST',

  fetchCompanyInfo: (payload?: CompanyInfoRequest) => ({
    type: actions.FETCH_COMPANYINFO_REQUEST,
    payload,
  }),
};

export default actions;
