import actions from './actions';
import { InitStateProps } from './types.d';

const initState: InitStateProps = {
  student: {
    extra: {
      tab_chave: null,
    },
  },
  listFinancial: {
    data: [],
    meta: {},
    extra: {},
  },
  listFiles: {
    data: [],
    meta: {},
    extra: {},
  },
};

export default function studentReducer(state = initState, action: any) {
  switch (action.type) {
    case actions.FETCH_STUDENT_SUCCESS: {
      return { ...state, student: action.payload.data };
    }

    case actions.UPDATE_STUDENT_SUCCESS: {
      return { ...state, student: action.payload.data };
    }

    case actions.FETCH_FINANCIAL_LIST_SUCCESS: {
      return {
        ...state,
        listFinancial: {
          data: action.payload ? action.payload.data : initState,
          meta: action.payload ? action.payload.meta : initState,
          extra: action.payload ? action.payload.extra : initState,
        },
      };
    }

    case actions.FETCH_FILES_LIST_SUCCESS: {
      return {
        ...state,
        listFiles: {
          data: action.payload ? action.payload.data : initState,
          meta: action.payload ? action.payload.meta : initState,
          extra: action.payload ? action.payload.extra : initState,
        },
      };
    }

    default:
      return state;
  }
}
