import React from 'react';
import * as Sentry from '@sentry/browser';

import App from 'next/app';
import Head from 'next/head';

import { withReduxSaga } from '../src/redux/store';
import { Provider } from 'react-redux';
import moment from 'moment';
import { themeConfig } from '../src/config';
import { CSSBaseline, theme, ThemeProvider } from '@l_ine/core';
import { getCookie } from '../src/helpers/session';
import progressBar from '../src/helpers/routerProgress';
import api from '../src/helpers/api';

moment.locale('pt-br');

progressBar();

// Inicialização do Sentry

if (process.env.NODE_ENV === 'production') {
  if (Sentry && Sentry.init && typeof Sentry.init === 'function') {
    Sentry.init({
      environment: process.env.NODE_ENV,
      dsn: 'https://97e9d61ead444de59810082a7799de2d@o206122.ingest.sentry.io/5338462',
    });
  }
}

//FULL-CALENDAR
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

class MyApp extends App<{ [name: string]: any }> {
  static async getInitialProps({ Component, ctx }: { Component: any; ctx: any }) {
    let cookieName = ctx.store.getState().Auth.cookieName;

    let info =
      ctx.store.getState().Company.companyInfo?.nome == ''
        ? { data: { nome: '', emp_codigo: null } }
        : { data: { ...ctx.store.getState().Company.companyInfo } };

    if (ctx.store.getState().Company.companyInfo?.nome == '') {
      info = await api.getCompanyInfo(
        ctx.req?.headers?.host?.replace('https://', '')?.replace('http://', '')
      );

      ctx.store.dispatch({
        type: 'FETCH_COMPANYINFO_SUCCESS',
        payload: info,
        ctx,
      });
    }

    const token = getCookie(cookieName, ctx.req);
    if (token) {
      ctx.store.dispatch({
        type: 'FETCH_TOKEN_REQUEST',
        payload: token,
        ctx,
      });
    }
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps, info };
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope: any) => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });

        window.location.pathname = '/500';
        Sentry.captureException(error);
      });
    }
  }

  componentDidMount() {
    const { store, info } = this.props;
    const cookieName = store.getState().Auth.cookieName;
    const token = getCookie(cookieName);

    if (token) {
      store.dispatch({
        type: 'FETCH_TOKEN_REQUEST',
        payload: token,
      });
    }

    store.dispatch({
      type: 'FETCH_COMPANYINFO_SUCCESS',
      payload: info,
    });

    // Configuração do escopo do Sentry
    //
    // Sentry.configureScope((scope) => {
    //   scope.setUser({
    //     id: store.getState().Auth.profile.emp,
    //     email: store.getState().Auth.profile.email,
    //     username: `${store.getState().Auth.profile.given_name} ${
    //       store.getState().Auth.profile.family_name
    //     }`,
    //     sisCodigo: store.getState().Auth.sistema.sis_codigo,
    //     sisName: store.getState().Auth.sistema.sis_name,
    //   });
    // });
  }

  render() {
    const { Component, pageProps, store, info } = this.props;

    let nome = store.getState().Company.companyInfo?.nome || '';
    return (
      <React.Fragment>
        <Head>
          {nome && <title>{nome}</title>}
          <link rel='icon' />
          <meta
            name='viewport'
            content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'
          />
        </Head>
        <Provider store={store}>
          <CSSBaseline />
          <Component {...pageProps} {...info} />
        </Provider>
      </React.Fragment>
    );
  }
}

export default withReduxSaga(MyApp);
