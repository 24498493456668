import { all, takeEvery, put, call, fork, spawn, select } from 'redux-saga/effects';
import Api from '../../helpers/api';
import actions from './actions';
import appActions from '../app/actions';
import Router from 'next/router';
import jwtDecode from 'jwt-decode';
import { removeCookie, setCookie, getCookie } from '../../helpers/session';
import redirect from '../../helpers/redirect';

import {
  actionFetchTokenRequest,
  actionLoginRequest,
  Success,
  actionLoginSuccess,
  actionForgotPasswordRequest,
  actionVerifyResetPasswordTokenRequest,
  actionResetPasswordRequest,
  RedirectWithTokenSuccess,
} from './types.d';

export const getToken = (state: any) => state.Auth.idToken;
export const getCookieName = (state: any) => state.Auth.cookieName;

export function* redirectWithToken() {
  yield takeEvery(
    actions.REDIRECT_WITH_TOKEN_REQUEST,
    function* ({ payload }: RedirectWithTokenSuccess) {
      try {
        const result: any = yield call(Api.verificarToken, payload);
        if (result?.data?.valid) {
          yield put({
            type: actions.REDIRECT_WITH_TOKEN_SUCCESS,
            remember: true,
            payload,
          });
        } else {
          redirect({}, '/signin');
        }
      } catch (error) {
        yield put({ type: appActions.IS_LOADING, payload: false });
        redirect({}, '/signin');
      }
    }
  );
}

export function* redirectWithTokenSuccess() {
  yield takeEvery(
    actions.REDIRECT_WITH_TOKEN_SUCCESS,
    function* ({ payload, remember }: RedirectWithTokenSuccess) {
      try {
        if (payload) {
          const decoded: any = jwtDecode(payload);
          if (decoded) {
            yield put({
              type: actions.SET_COOKIE_NAME,
              payload: `id_token`,
            });
            setCookie(`id_token`, payload, Boolean(remember));

            if (decoded.emp == 0) {
              return redirect({}, '/empresa');
            }

            if (decoded.tipo == 0) {
              redirect({}, '/dashboard/venda?showBackButton=1');
            } else {
              redirect({}, '/dashboard');
            }
          }
        }
      } catch (error) {
        redirect({}, '/signin');
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
      yield put({ type: appActions.IS_LOADING, payload: false });
    }
  );
}

export function* fetchTokenRequest() {
  yield takeEvery(actions.FETCH_TOKEN_REQUEST, function* ({ payload }: actionFetchTokenRequest) {
    const decoded = jwtDecode(payload);
    if (decoded) {
      yield put({
        type: actions.FETCH_TOKEN_SUCCESS,
        payload: { idToken: payload, profile: decoded },
      });
    }
    yield setCookie('id_token', payload, true);
    yield put({
      type: actions.SET_COOKIE_NAME,
      payload: 'id_token',
    });
  });
}

export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* ({ payload }: actionLoginRequest) {
    try {
      yield put({ type: appActions.IS_LOADING, payload: true });
      const result: any = yield call(Api.login, payload);
      yield put({
        type: actions.LOGIN_SUCCESS,
        remember: payload.remember,
        payload: result,
        primeiroAcc: result.primeiro_acesso,
      });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}

export function* logoutRequest() {
  yield takeEvery(actions.LOGOUT_REQUEST, function* () {
    try {
      yield put({ type: appActions.IS_LOADING, payload: true });
      const token: string = yield select(getToken);
      yield call(Api.logout, token);
      const mToken: string = yield getCookie('id_token');
      if (mToken && token != mToken) {
        yield call(Api.logout, mToken);
      }
      const cookieName = select(getCookieName);

      yield put({ type: appActions.IS_LOADING, payload: false });
      yield put({ type: actions.LOGOUT_SUCCESS, payload: cookieName });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}

export function* forgotPasswordRequest() {
  yield takeEvery(
    actions.FORGOT_PASSWORD_REQUEST,
    function* ({ payload }: actionForgotPasswordRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(Api.forgotPassword, payload);
        yield put({ type: actions.FORGOT_PASSWORD_SUCCESS, payload: result });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* verifyResetPasswordTokenRequest() {
  yield takeEvery(
    actions.VERIFY_RESET_PASSWORD_TOKEN_REQUEST,
    function* ({ payload }: actionVerifyResetPasswordTokenRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        yield put({ type: actions.VERIFY_TOKEN_INVITE_SUCCESS });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield Router.push('/signin');
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* resetPasswordRequest() {
  yield takeEvery(
    actions.RESET_PASSWORD_REQUEST,
    function* ({ payload }: actionResetPasswordRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(Api.resetPassword, payload);
        yield put({ type: actions.RESET_PASSWORD_SUCCESS, payload: result });
        yield put({ type: appActions.IS_LOADING, payload: false });

        Router.replace(`/signin`);

        if (result.error || result.errors || result.erro) {
          yield put({ type: appActions.HANDLE_ERRORS, payload: result });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* loginSuccess() {
  yield takeEvery(
    actions.LOGIN_SUCCESS,
    function* ({ payload, remember, ctx, primeiroAcc }: actionLoginSuccess) {
      if (payload.token) {
        const decoded = jwtDecode(payload.token);
        if (decoded) {
          yield setCookie('id_token', payload.token, remember);
          yield put({
            type: actions.SET_COOKIE_NAME,
            payload: 'id_token',
          });

          redirect(ctx, '/dashboard');
        }
      }
      yield put({ type: appActions.IS_LOADING, payload: false });
    }
  );
}

export function* logoutSuccess() {
  yield takeEvery(actions.LOGOUT_SUCCESS, function* ({ payload }: Success) {
    yield put({ type: appActions.IS_LOADING, payload: true });
    const cookieName: string = yield select(getCookieName);
    const payToken = String(payload.token);
    removeCookie(payToken ? payToken : cookieName);
    removeCookie('id_token');

    if (!!payload.site) {
      window.location.href = payload.site.split(' ')[0];
    } else {
      Router.replace('/signin');

      if (window) {
        window.location.reload();
      }
    }
    yield put({ type: appActions.IS_LOADING, payload: false });
  });
}

export function* signupSuccess() {
  yield takeEvery(actions.SIGNUP_SUCCESS, function* ({ payload }: Success) {
    if (payload) {
      Router.replace(`/signin?email=${payload.email}`);
    }
    yield;
  });
}

export function* forgotPasswordSuccess() {
  yield takeEvery(actions.FORGOT_PASSWORD_SUCCESS, function* ({ payload }: Success) {
    if (payload) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: {
          activated: true,
          message: payload.messages[0],
        },
      });
      Router.replace(`/signin`);
    }
  });
}

export function* resetPasswordSuccess() {
  yield takeEvery(actions.RESET_PASSWORD_SUCCESS, function* ({ payload }: Success) {
    if (payload) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: {
          activated: true,
          message: payload.messages[0],
        },
      });
      yield Router.push('/signin');
    }
  });
}

export default function* rootSaga() {
  yield all([
    spawn(fetchTokenRequest),
    spawn(loginRequest),
    spawn(logoutRequest),
    spawn(redirectWithToken),
    spawn(forgotPasswordRequest),
    spawn(verifyResetPasswordTokenRequest),
    spawn(resetPasswordRequest),
    fork(resetPasswordSuccess),
    fork(forgotPasswordSuccess),
    fork(signupSuccess),
    fork(loginSuccess),
    fork(logoutSuccess),
    fork(redirectWithTokenSuccess),
  ]);
}
