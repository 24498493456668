import { combineReducers } from 'redux';
import App from './app/reducer';
import Auth from './auth/reducer';
import Company from './company/reducer';
import Student from './student/reducer';

export default combineReducers({
  App,
  Auth,
  Company,
  Student,
});
